export const regexObject = {
  EMAIL_INVALID:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  CONTACT_INVALID: /^[789]\d{9}$/,
  PINCODE_INVALID: /^[1-9][0-9]{5}$/,
  BATCH_INVALID: /^\d{4}$/,
  INTGER_ONLY: /^[0-9]*$/,
  //PASSWORD : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*_])(?=.{6,}$)/,
  STRING_ONLY: /^[A-Za-z]+$/,
  STRING_ONLY_WITH_WHITESPACE: /^[A-Za-z\s]+$/,
  STRING_ONLY_WITH_SPECIAL: /^[A-Za-z\s\,]+$/,
  WEBSITE_REGEX: /^((https?|ftp|smtp):\/\/)?(www.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
  DOMAIN_REGEX: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
  IFSC_INVALID: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  ACCOUNT_NUMBER_INVALID: /^\d{9,18}$/,
  CHEQUE_NUMBER_INVALID: /^\d{6}$/,
  DRAFT_NUMBER_INVALID: /^\d{6}$/,
  MOBILE_INVALID: /^[0-9]{7,15}$/,
  AADHAAR_INVALID: /^[2-9]\d{11}$/,
  SARAL_ID_INVALID: /^[0-9]{19}$/,
  APPLICATION_NO_INVALID: /^[a-zA-Z0-9]*$/,
  PERCENTAGE_INVALID: /^(100|100\.0*|([0-9]{1,2}|[0-9]{1,2}\.[0-9]*))$/,
  ABC_ID_INVALID: /^[0-9]{12}$/,
  ASCII_ONLY: /^(?!\s$)[\x20-\x7E]*$/,
  ASCII_WITH_WHITESPACE: /^(?!\s$)[\x20-\x7E\s]*$/,
  ALPHA_NUMERIC_ONLY: /^(?!0+$)[a-zA-Z0-9]*$/,
  ALPHA_NUMERIC_WITH_SPECIAL: /^[A-Za-z0-9\s\'\.\&]+$/,
  MARKS: /^(N(A|)|A(B|)|\d*|)$/,
  GR_NUMBER: /^[!-~]*\d$/,
} as any;
